@import '../theme.scss';

.doubleCheckboxInput {
  font-size: 0;

  &.invalid {
    .checkboxes {
      .checkbox {
        button {
          border-color: $color-red;
        }
      }
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 35px);

    p {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.01em;
      color: $color-dark-grey;
      text-align: left;

      a {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }

  .checkboxes {
    margin-top: 10px;

    .checkbox {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin-left: 10px;
      cursor: pointer;

      &.checked {
        button {
          .check {
            opacity: 1;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }

      button {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 5px;
        border: 1px solid $color-dark-grey;
        background-color: $color-light-grey;
        position: relative;
        transition: border 0.2s ease-in-out;

        .check {
          position: absolute;
          top: 50%;
          left: 50%;
          translate: -50% -50%;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          width: 50%;
          height: 50%;
          background-color: $color-blue;
          border-radius: 2.5px;
          overflow: hidden;
        }
      }

      p {
        color: $color-dark-grey;
        font-size: 14px;
        font-weight: 500;
        color: #7a7a7a;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
      }
    }
  }
}
